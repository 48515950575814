.App {
  text-align: center;
}

.App-logo {
  /*animation: App-logo-spin infinite 20s linear;*/
  height: 50vmin;
}

.App-header {
  background-color: #ffffff;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: #3f4143;
}

.App-content {
  background-color: #ffffff;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 1.5vmin);
  color: #3f4143;
}

.colorpool {
  color: #3f4143;
  color: #565a5b;
  color: #f8a31e;
  color: #ed1483;
  color: #75ae2b;
}

.App-link {
  color: #3f4143;
  text-decoration: none;
}

.App-link:hover {
  color: #75ae2b;
}

.icon-label {
  margin-right: 0.5em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
